<template>
  <b-modal id="modal-edit-server" size="md" no-close-on-backdrop>
    <!-- custom modal title -->
    <template #modal-title>
      <div class="d-flex align-items-center">
        <feather-icon size="20" icon="EditIcon" class="mr-05" />
        <span class="fs-18">Edit Informasi Server</span>
      </div>
    </template>
    <!-- form container -->
    <loader-component v-if="is_loading" />
    <b-card no-body class="mb-0">
      <b-card-body>
        <validation-observer ref="biodataRules">
          <b-form>
            <!-- nama server -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Nama Server"
                rules="required"
              >
                <label for="nama-server"
                  >Nama Server <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="ServerIcon" />
                  </b-input-group-prepend>

                  <b-form-input
                    v-model="data.name"
                    id="nama-server"
                    placeholder="Masukkan Nama Server"
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
                <small
                  v-if="error_message"
                  class="text-danger"
                  style="font-size: 10px"
                >
                  {{ error_message }}
                </small>
              </validation-provider>
            </b-form-group>
            <!-- URL Grafana -->
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="URL-Grafana"
                rules="required"
              >
                <label for="URL-Grafana">
                  URL Grafana <span class="text-danger">*</span>
                </label>
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="LinkIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="data.grafana_url"
                    id="URL-Grafana"
                    placeholder="Masukkan URL Grafana"
                  />
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <!-- custom modal footer -->
    <template #modal-footer>
      <!-- button batal -->
      <b-button
        variant="outline-danger"
        size="sm"
        class="float-right mr-05"
        @click="cancelEditServer"
      >
        Batal
      </b-button>
      <!-- button simpan -->
      <b-button
        variant="outline-info"
        size="sm"
        class="float-right"
        @click.prevent="editServer"
      >
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import floating_loader from "@/views/components/floatingLoader.vue";

export default {
  name: "editServer",
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    "loader-component": floating_loader,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      is_loading: false,
      error_message: false,
      //   validation
      required,
    };
  },
  methods: {
    editServer() {
      this.$refs.biodataRules.validate().then((success) => {
        if (success) {
          this.is_loading = true;
          this.error_message = false;
          let api =
            process.env.VUE_APP_API_URL + "server/update/" + this.data._id;
          let params = {
            name: this.data.name,
            grafana_url: this.data.grafana_url,
          };
          useJwt.axiosIns
            .put(api, { data: params })
            .then(() => {
              this.server_data = {
                name: null,
                grafana_url: null,
              };
              this.$bvModal.hide("modal-edit-server");
              this.$parent.getData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Data Server berhasil diupdate`,
                },
              });
            })
            .catch((err) => {
              this.error_message =
                err.response.data.detail.message ||
                "Terjadi Kesalahan Pada Sistem!";
            })
            .finally(() => {
              this.is_loading = false;
            });
        }
      });
    },
    cancelEditServer() {
      this.server_data = {
        name: null,
        grafana_url: null,
      };
      this.$bvModal.hide("modal-edit-server");
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
<style>
.modal-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 20px !important;
  font-weight: bold;
}
</style>
